.bg-gradient {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#37578d+1,702f4c+100 */
  background: #37578d; /* Old browsers */
  background: -moz-linear-gradient(
                  -45deg,
                  #37578d 1%,
                  #702f4c 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
                  -45deg,
                  #37578d 1%,
                  #702f4c 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
                  135deg,
                  #37578d 1%,
                  #702f4c 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#37578d', endColorstr='#702f4c', GradientType=1); /* IE6-9 fallback on horizontal gradient */
}

.vh-100 {
  height: 100vh;
}

.main-block {
  width: 1280px;
  height: 600px;

  @media screen and (max-width: 1280px) {
    width: 100%;
    height: 100%;
  }
}

.score {
  width: 100%;
  height: 100%;

  & input {
    display: none;
  }

  & label {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: block;
    line-height: 50px;
    text-shadow: 1px 1px 2px #000000;
  }

  & input:checked + label {
    line-height: 40px;
    border: 5px solid white;
  }
}

.login-bg {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#3b81f6+50,ffffff+50 */
  background: #3b81f6; /* Old browsers */
  background: -moz-linear-gradient(
                  -45deg,
                  #3b81f6 50%,
                  #ffffff 50%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
                  -45deg,
                  #3b81f6 50%,
                  #ffffff 50%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
                  135deg,
                  #3b81f6 50%,
                  #ffffff 50%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3b81f6', endColorstr='#ffffff', GradientType=1); /* IE6-9 fallback on horizontal gradient */
}

.svg-white {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.svg-blue {
  filter: invert(44%) sepia(34%) saturate(2046%) hue-rotate(193deg) brightness(97%) contrast(98%);
}

.menu {
  @media screen and (max-width: 1000px) {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 50px;
    overflow: hidden;
  }
}

.menu-active {
  background-color: #fff;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  position: relative;
  margin-left: -15px;
  padding-left: 15px;
  color: #000;

  & img {
    filter: invert(0%) sepia(8%) saturate(7494%) hue-rotate(302deg) brightness(84%) contrast(101%);
  }

  @media screen and (max-width: 1000px) {
    border-radius: 15px;
  }

  @media screen and (min-width: 1000px) {
    & > .b-rad-first {
      position: absolute;
      top: -20px;
      right: 0;
      background-color: #fff;
      width: 20px;
      height: 20px;
      border: none;
    }

    & > .b-rad-second {
      position: absolute;
      bottom: -20px;
      right: 0;
      background-color: #fff;
      width: 20px;
      height: 20px;
      border: none;
    }

    &::after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      top: -20px;
      right: 0;
      background-color: #3b81f6;
      border-bottom-right-radius: 15px;
      border: none;
    }

    &::before {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      right: 0;
      bottom: -20px;
      z-index: 1;
      background-color: #3b81f6;
      border-top-right-radius: 15px;
      border: none;
    }
  }
}

.menu-icon {
  display: none;

  @media screen and (max-width: 1000px) {
    display: block;
  }
}

// LOADER
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/// LOADER

// MINI-LOADER
.lds-mini-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}

.lds-mini-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border: 3px solid;
  border-radius: 50%;
  animation: lds-mini-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-mini-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-mini-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-mini-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-mini-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/// MINI-LOADER

.wallet {
  width: 350px;
  min-height: 490px;

  & div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.site {
  & div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.table-head {
  position: relative;

  & > span {
    display: inline-block;
    padding-right: 20px;

    &:last-child {
      position: absolute;
      right: -16px;
      top: 10px;
    }
  }
}

.min-h-40 {
  min-height: 400px;
}

th {
  & > span > div:first-child {
    bottom: -60px;
    left: -100px;
  }

  &:first-child > span > div:first-child {
    left: -50px;
  }

  &:last-child > span > div:first-child {
    left: -170px;
  }
}

.btn-request-init:disabled {
  cursor: wait !important;
  background-color: #8ea3bc !important;
}

.text-shadow {
  text-shadow: 5px 2px 20px black, 5px 2px 20em;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select:invalid {
  color: gray;
}

input:disabled {
  background-color: gray !important;
  color: black !important;
  -webkit-text-fill-color: black;
  opacity: 1;

}

.required-field:after {
  content: '*';
  height: 100px;
  width: 100px;
  color: red;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.select-without-arrow > select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.align-items-center{
  align-items: center;
}