.customCheckbox {
  .inputField {
    display: none;
  }

  .inputField + span {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 12px;
    height: 12px;
    margin: -1px 0px 0 0;
    vertical-align: middle;
    background: white left top no-repeat;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  .inputField:checked + span {
    background: #D9534F -19px top no-repeat;
  }
  
  .inputField + span {
    margin-right: 4px;
  }
}
